import React from 'react';
import '../../css/Studyguide.css';
import { Link } from 'react-router-dom';

export default function Studyguide() {
    return (
        <div className="study-main">
            <div className="heading-holder">
                <h6>Ace Learning Challenge</h6>
                <p>This is the study guide for ace learning Challenge</p>
            </div>

            <div className="guide-card-holder">

                <div className="top">
                    <div className="guide-card">
                        <p className="week-no">
                            week 1
                        </p>
                        <h5 className="card-title">
                            Social Studies 2018
                        </h5>
                        <p className="card-cont">
                            Answer your mapping questions 100% accurately by studying this topic
                        </p>
                        <button className="more"><a id='unstyle' target="_blank" href="https://learn.e-limu.org/topic/view/?c=29">Read More</a><span><img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/blue-arrow.png" alt="icon" /></span></button>
                    </div>

                    <div className="guide-card">
                        <p className="week-no">
                            week 3
                        </p>
                        <h5 className="card-title">
                            Science 2017
                        </h5>
                        <p className="card-cont">
                            Did you know the human body is made up of more than 3 different systems which function differently?
                        </p>
                        <button className="more"><a id='unstyle' target="_blank" href="https://learn.e-limu.org/topic/view/?c=1">Read More</a><span><img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/blue-arrow.png" alt="icon" /></span></button>
                    </div>
                </div>

                <div className="bottom">
                    <div className="guide-card">
                        <p className="week-no">
                            week 4
                        </p>
                        <h5 className="card-title">
                            CRE 2017
                        </h5>
                        <p className="card-cont">
                            What did Jesus have to say about life and health?                        </p>
                        <button className="more"><a id='unstyle' target="_blank" href="https://learn.e-limu.org/topic/view/?c=37">Read More</a><span><img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/blue-arrow.png" alt="icon" /></span></button>
                    </div>

                    <div className="guide-card">
                        <p className="week-no">
                            week 5
                        </p>
                        <h5 className="card-title">
                            English 2019
                        </h5>
                        <p className="card-cont">
                            Answer your sentence completion questions accurately with tips in this study
                        </p>
                        <button className="more"><a id='unstyle' target="_blank" href="https://learn.e-limu.org/topic/view/?c=154">Read More</a><span><img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/blue-arrow.png" alt="icon" /></span></button>
                    </div>
                </div>


            </div>
        </div>
    )
}
