import React from "react";

export default function (props) {
    const { question, year, answer, numberOfQuestions, answerQuestion, currentQuestion } = props;


    const getQuestionHtml = (question) => {
        let questionHtml = question.statement;
        let c = 0;
        questionHtml = questionHtml.replace(/\[resource:\s+(\d+),\s+align:\s+[^\]]+\]/, () => {
            let url = question.image_resources[c].avatar;
            c++;
            return `
            <img src="${url}" />
            `
        })
        return questionHtml
    }

    const getQuestionOptionHtml = (question, choice, position) => {
        const choiceHTML = question.choices[choice];
        return choiceHTML.replace(/\[resource:\s+(\d+),\s+align:\s+[^\]]+\]/, () => {
            let url = question.choices.choices_image[position].image_url;
            return `
            <img src="${url}" />
            `
        })
    }

    React.useEffect(() => {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, document.getElementsByClassName("math-tex")])
    }, [question.question_number]);


    return (
        <div>
            <div className="qyt">

                <div>
                    <p className="year-txt"><strong>{year}</strong></p>
                </div>
                <div>
                    <p className="q-number">No. of Questions : <strong>{currentQuestion + 1} of {numberOfQuestions}</strong></p>
                </div>

            </div>
            <div className="q-content">
            <h6 className="q-numero"><strong>{currentQuestion + 1}</strong></h6>
            <div dangerouslySetInnerHTML={{ __html: getQuestionHtml(question) }} />
            </div>
            <div className="q-choices">
                {["A", "B", "C", "D"].map((option, idx) => (
                    <div className={"choice " + (answer === option ? "active" : "")} key={idx}>
                        
                        <button onClick={() => answerQuestion(question, option)} className="q-btn">
                        <p className="qa">{option}.</p>
                            <div className="content" dangerouslySetInnerHTML={{ __html: getQuestionOptionHtml(question, option, idx) }} />
                        </button>
                    </div>
                ))}
            </div>

        </div>
    )
}