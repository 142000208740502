import React, { useState, useEffect } from 'react';
import '../../css/Subjects.css';
import api from "../../api";
import { Link } from 'react-router-dom';
import endpoints from "../../endpoints";
import ExamIdData from "../../ExamIdData.json";
import Yearsmodal from "../../components/Years/Years";
import Modal from 'react-modal' ;

export default function Subjects() {

    const [items, setItems] = useState([]);
    const [subjectTopicIDs, setSubjectTopicIDs] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const fetchSubjects = () => {
        api.get(endpoints.subjects)
            .then(response => response.data)
            .then(data => setItems(data));
    }


    useEffect(() => {
        fetchSubjects();
        fetchTopicIds();
        fetchSubjectIds();
    }, []);

    const fetchTopicIds = () => {
        let subjectTopics = {}
        ExamIdData.map(examid => {
            subjectTopics[examid.subject_id] = examid.exam_ids[Math.floor(Math.random() * examid.exam_ids.length)]

        })
        setSubjectTopicIDs(subjectTopics);
        console.log(subjectTopics)
    }

    const fetchSubjectIds = () => {
        ExamIdData.map(subjectId => {
            let subjectIds = subjectId.subject_id;
            return console.log(subjectIds);
        })
    }

    const linkStyle = {
        color: '#fff',
        textDecoration: 'none'
    }

    const hidePromotion = () => {
        const promotion = document.getElementById('hide-prom-lead');
        promotion.style.display = 'none';
    }

    

    

    return (
        <>
        <div className="prom-lead-blue" id="hide-prom-lead">
                <p>Hurray, you just unlocked a promo code of 50KSH. Use this code<strong>"PREP"</strong> to access all exams for a <strong>week!😅</strong></p>
                <button className="prom-lead-btn-blue" onClick={() => hidePromotion()}>X</button>
            </div>
        <div className="main-subject-div">
            
            
                <div className='subject-rows'>
                    {items.map(item => (

                        <div className="single-subject" key={item.id + 2}>
                            <div className="wrap-subject">
                                <img className='sub-img' src={item.icon} alt="" id="sbj-img" />
                                <h5 className="sub-name">{item.name}</h5>
                            </div>
                            <div className="extr">

                                <Link to={`/years/${item.id}`} style={linkStyle} key={item.id}>
                                    <button className="to-years" id="extra-btn" onClick={() => setModalIsOpen(true)}>
                                        Do an exam
                                    </button>
                                </Link>


                                {(![9, 10].includes(item.id)) && (
                                    <Link to={`/subjects/${item.id}/selecttopic/${subjectTopicIDs[item.id]}`} style={linkStyle} key={item.id + 1}>
                                        <button className="to-topics" id="extra-btn" >
                                            Do Exam by Topic
                                        </button>
                                    </Link>
                                )}

                            </div>
                            <div className="otherextras">
                                <div className="examsdone">
                                    <p className="exdone kang">Exams Done: <strong> </strong></p>
                                </div>
                                <div className="topdone">
                                    <p className="tdone kang">Topics Done: <strong> </strong></p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            
            <Modal isOpen ={modalIsOpen}>
                <Yearsmodal></Yearsmodal>
            </Modal>
        </div>
        </>
    )
}