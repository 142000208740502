// AUTH
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";
export const AUTH_GET_USER = "AUTH_GET_USER";
export const AUTH_GET_USER_FAIL = "AUTH_GET_USER_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REQUEST_SENT = "AUTH_REQUEST_SENT";
export const AUTH_REQUEST_DONE = "AUTH_REQUEST_DONE";
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_SIGNUP_FAIL = 'AUTH_SIGNUP_FAIL';

