import React, { useState } from 'react';
import '../css/App.css';
import Header from './Header/Header';
import Subjects from './Subjects/Subjects';
import Years from './Years/Years';
import Questions from './Questions/Questions';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Signup from './Auth/Signup';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import IsAuthenticated from './Auth/IsAuthenticated';
import Studyguide from './Landing/Studyguide';
import Results from './Results/Results';
import Allanswers from './Results/Allanswers';
import Examresults from './Examresults/Examresults';
import NotFound from './misc/NotFoundComponent';
import Perfomance from './Results/Perfomance';
import { Provider } from 'react-redux';
import store from '../redux/store';
import { Selecttopic } from './Selecttopic/Selecttopic';
import { Betweenyear } from './Selecttopic/Betweenyear';
import { onMessageListener } from '../firebase';
import Notifications from './Notifications/Notification';
import ReactNotificationComponent from './Notifications/Reactnotification';
import Leaderboard from './LeaderBoard/Leaderboard';
import Preplanding from './Landing/Preplanding';
import Paymentplan from './Transactions/Paymentplan';
import Contactus from './contactus/Contactus';

const AppFrame = (props) => {
  const { children } = props;
  return (
    <div>
      <Header></Header>
      <div className="content-wrapper">
        {children}
      </div>
    </div>
  )

}


function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  console.log(show, notification);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));


  return (
    <>
      <div className="App">
        {show ? (
          <ReactNotificationComponent
            title={notification.title}
            body={notification.body}
          />
        ) : (
          <></>
        )}
        <Notifications />
      </div>

      <Provider store={store}>
        <Router history={createBrowserHistory()}>
          <div>
            <AppFrame>
              <Switch>
                <Route path="/" exact component={Preplanding} />
                <Route path="/subjects" exact component={Subjects} />
                <Route path="/selecttopic/:id" exact component={Selecttopic} />
                <Route path="/years/:id" exact component={IsAuthenticated(Years)} />
                <Route path="/subjects/:id/selecttopic/:year_id" exact component={IsAuthenticated(Betweenyear)} />
                <Route path="/subjects/:id/questions/:year_id" exact component={Questions} />
                <Route path="/subjects/:id/questions/:year_id/topic/:topic_id" exact component={Questions} />
                <Route path="/results" exact component={IsAuthenticated(Results)} />
                <Route path="/leaderboard" exact component={IsAuthenticated(Leaderboard)} />
                <Route path="/contactus" exact component={Contactus} />
                <Route path="/allanswers" component={IsAuthenticated(Allanswers)} />
                <Route path="/subjects/:id/signup" component={Signup} />
                <Route path="/subjects/:id/login" component={Login} />
                <Route path="/login" exact component={Login} />
                <Route path="/signup" exact component={Signup} />
                <Route path="/logout" component={Logout} exact />
                <Route path="/study-guide" exact component={Studyguide} />
                <Route path="/paymentplan" exact component={IsAuthenticated(Paymentplan)} />
                <Route path="/examresults" exact component={IsAuthenticated(Examresults)} />
                <Route path="/perfomance" exact component={IsAuthenticated(Performance)} />
                <Route component={NotFound} />
              </Switch>
            </AppFrame>
          </div>

        </Router>
      </Provider>
    </>
  );
}



export default App;
