import React from 'react'
import '../../css/Paymentplan.css'

export default function Paymentplan() {
    return (
        <>
            <div>
                <div className='holder'>
                    <div className="top-text">
                        <h2>Subscription Plan</h2>
                    </div>
                    <div className="payment-plan">

                        <div className="cardi">
                            <div className="cardi-header">
                                <h1>50<br/>kes</h1>
                            </div>
                            <div className="acc-header">
                                <h2>1 Week <br/> Access</h2>
                            </div>
                            <div className="cardi-body">
                                <p className="cardi-text">Get access to Prep for <br/> <strong>a week</strong></p>
                            </div>
                        </div>

                        <div className="cardi">
                            <div className="cardi-header">
                                <h1>180 <br/>kes</h1>
                            </div>
                            <div className="acc-header">
                                <h2>1 Month <br/> Access</h2>
                            </div>
                            <div className="cardi-body">
                                <p className="cardi-text">Get access to Prep for<br/> <strong>a Month</strong></p>
                            </div>
                        </div>

                        <div className="cardi">
                            <div className="cardi-header">
                                <h1>400 <br/>kes</h1>
                            </div>
                            <div className="acc-header">
                                <h2>3 Months <br/> Access</h2>
                            </div>
                            <div className="cardi-body">
                                <p className="cardi-text">Get access to Prep for <br/> <strong>3 Months</strong></p>
                            </div>
                        </div>

                        <div className="cardi">
                            <div className="cardi-header">
                                <h1>1000<br/> kes</h1>
                            </div>
                            <div className="acc-header">
                                <h2>1 Year <br/> Access</h2>
                            </div>
                            <div className="cardi-body">
                                <p className="cardi-text">Get access to Prep for <br/> <strong>a whole Year</strong></p>
                            </div>
                        </div>


                    </div>
                    <div className="confirm-input">
                        <div className="conf-wrapper">
                            <div className="mpesa-top">
                                <img src={process.env.PUBLIC_URL + '/mpesa.png'} alt="" />
                                <h3>Pay for subscription here</h3>
                            </div>
                            <div className="mpesa-instructions">
                                <p>1. Go to Buy Goods and Services, enter Till Number <strong>787 499</strong></p>
                                <p>2. Enter amount: <i>based on subscription you want</i></p>
                                <p>3. After payment, paste the transaction code below.</p>
                            </div>
                            <div className="mpesa-input">
                                <input type="text" placeholder="Paste transaction code here" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" />
                                <button className="post-conf">Buy Subscription</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
