import {AUTH_LOGIN,AUTH_GET_USER, AUTH_LOGIN_FAIL,
    AUTH_GET_USER_FAIL, AUTH_LOGOUT, AUTH_REQUEST_SENT,
     AUTH_REQUEST_DONE,AUTH_SIGNUP, AUTH_SIGNUP_FAIL} from './action-types';
import api from '../../api'
import endpoints from '../../endpoints'

const setToken = (token)=>{
    window.localStorage.setItem("token",token);
}

const removeToken = ()=>{
    window.localStorage.removeItem("token");
}

export const requestSentAction = ()=>{
    return {
        type:AUTH_REQUEST_SENT
    }
}

export const requestDoneAction = ()=>{
    return {
        type:AUTH_REQUEST_DONE
    }
}

export const loginAction = ()=>{
    return {
        type:AUTH_LOGIN,
    }
}

export const loginActionFail = (error)=>{
    return {
        type:AUTH_LOGIN_FAIL,
        error:error
    }
}

export const getUserAction = (user)=>{
    return {
        type:AUTH_GET_USER,
        user:user,
    }
}

export const getUserActionFail = (error)=>{
    return {
        type:AUTH_GET_USER_FAIL,
        error:error,
    }
}

export const logOutUserAction = ()=>{
    return {
        type:AUTH_LOGOUT
    }
}

export const signUpUserAction = ()=>{
    return {
        type:AUTH_SIGNUP
    }
}
export const signUpUserActionFail = (error)=>{
    return {
        type:AUTH_SIGNUP_FAIL,
        error:error,
    }
}
const createErrorMessage = (error, context, defaultCode, defaultMessage) => {
    let errorMessage= defaultMessage?defaultMessage:"Error occured try again";
    let errorCode=defaultCode?defaultCode:500;
    let codeErrors ={
        500:'Server Error',
        400:'Bad Request',
        401:'Unauthorized Reques',
    }
    if(error.response){
        if(error.response.data && error.response.data.error){
            errorMessage = error.response.data.error;
        }else{
            console.log(error.response);
            errorCode = error.response.status;
            if(error.response.headers['content-type']==='application/json'){
                let detail = error.response.data.detail;
                errorMessage = detail ? detail : errorMessage;
            }else{
                if(Object.keys(codeErrors).includes(errorCode)){
                    errorMessage = codeErrors[errorCode];
                }
            }
        }
    }
    return {context:context, message:errorMessage, code:errorCode};
}

export const getUser = ()=>{
    return dispatch=>{
        dispatch(requestSentAction())
        api.get(endpoints.user)
        .then(res=>{
            console.log(res);
            dispatch(requestDoneAction())
            dispatch(getUserAction(res.data))
        }).catch(error=>{
            dispatch(requestDoneAction())
            console.log(error);
            let resError = createErrorMessage(error, 'getUser');
            dispatch(getUserActionFail(resError));
        })
    }
}

export const loginUser = (username, password)=>{
    return dispatch=>{
        dispatch(requestSentAction())
        api.post(endpoints.login,{username:username,password:password})
        .then(res=>{
            dispatch(requestDoneAction())
            if(res.status===200){
                console.log(res);
                setToken(res.data.key);
                dispatch(loginAction())
            }
        }).catch(error=>{
            dispatch(requestDoneAction())
            let responseError = createErrorMessage(error, 'login');
            dispatch(loginActionFail(responseError))
        })
    }
}

export const logOutUser = ()=>{
    return dispatch=>{
        removeToken();
        dispatch(logOutUserAction());
    }
}

export const signUpUser = (user)=>{
    return dispatch=>{
        dispatch(requestSentAction());
        api.post(endpoints.register,user).then(res=>{
            console.log(res);
            dispatch(requestDoneAction());
            setToken(res.data.token);
            dispatch(signUpUserAction());

        }).catch(error=>{
            dispatch(requestDoneAction());
            let responseError = createErrorMessage(error, 'signUp');
            dispatch(signUpUserActionFail(responseError));
            console.log(error);
        })
    }
}
