import React from 'react'
import '../../css/Pagination.css';

const Pagination = ({postsPerPage, totalPosts, paginate}) => {

    const pageNumbers = [];

    for(let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++){
        pageNumbers.push(i);
    }

    return (
            <div className="pagination">
                {pageNumbers.map(number => (
                    <li key={number}  onClick={() => paginate(number)} className="page-link">
                            {number}
                    </li>
                ))}
            </div>
    )
} 

export default Pagination;
