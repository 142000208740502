import styled from "styled-components";

export default styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: left;
height: auto;
width: 100%;
margin:0 0px;
font-size: 4em;
`;