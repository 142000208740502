import React, {useState, useEffect} from 'react';
import api from '../../api';
import '../../css/Examreslts.css';
import endpoints from '../../endpoints';

export default function Examresults() {

    const [results, setResults] = useState([]);
    const [resultsubjects, setResultSubjects] = useState([]);


    useEffect(() => {
        fetchResults();
        fetchSubjects();
        console.log(resultsubjects)
    }, [])

    const fetchResults = () => {
        api.get(endpoints.studentResults)
        .then(response => response.data)
        .then(data => setResults(data.exams_results))
        
    }

    const fetchSubjects = () => {
        api.get(endpoints.subjects)
        .then(response => response.data)
        .then(data => setResultSubjects(data.subjects))
       
    }
        
    return (
        <div className="main-holder">
            <div className="balance">
                <h4>All Exam Results</h4>
            </div>
       
            
            {results.map(result => (
                
                 <div className="single-result">
               <div className="topic-holder">

               <div className="topic-tittle">
                   <p>Subject:{result.subject_id}</p> 
                   <p className="topic-tittle">
                      Topic:{result.topic__title}
                   </p>
                   <p>time taken:{result.time_taken}</p> 
               </div>

               <div className="marks">
                   <h1 className="marks">
                       {result.marks}%
                   </h1>
               </div>
           </div> 
           </div>
            ))}
            
            
       
        
    </div>
    )
}
