import React from "react";
import "../../css/CloseQuestions.scss";
import ChoicesQuestion from "./ChoicesQuestion";


export default function TextQuestion(props) {
    const [open, setOpen] = React.useState(false);

    const {question,topics, numberOfQuestions, year, answer, answerQuestion, resource, currentQuestion} = props;
    const statement = resource.statement;

    return (
        <>
            <div className="close-text-question">
                <details open={question.id === resource.questions[0]}>
                    <summary style={{textAlign: 'left'}}>Show Passage</summary>
                    <div className="questions-section content"dangerouslySetInnerHTML={{__html: statement}}/>
                </details>
            </div>
            <ChoicesQuestion
                question={question}
                year={year}
                answer={answer}
                answerQuestion={answerQuestion}
                currentQuestion={currentQuestion}
                numberOfQuestions={numberOfQuestions}
            />
        </>
    )
}
