import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/not-found.css';



const NotFoundComponent = (props)=>{
    return (
        <div className ="nf-container">
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>The Page you are looking for doesn't exist or an other error occured. Go to</p>
            <Link id ="home-link" to="/"><button className = "nf-btn" >Home Page</button></Link>
        </div>
    )
}

export default NotFoundComponent;