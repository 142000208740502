import React, { useState, useEffect } from 'react';
import { Link, Prompt } from 'react-router-dom';
import '../../css/Questions.css';
import ChoicesQuestion from "./ChoicesQuestion";
import CloseText from "./CloseText";
import TextQuestion from "./TextQuestion";
import endpoints from "../../endpoints";
import api from "../../api";
import { Selecttopic } from '../Selecttopic/Selecttopic';
import _ from "lodash";

export default function
    (props) {
    const subjectId = props.match.params['id'];
    const yearId = props.match.params['year_id'];
    const topicId = props.match.params['topic_id'] ?? null;
    const [year, setYear] = useState('');
    const [topic, setTopic] = useState({});
    const [questions, setQuestions] = useState(null);
    const [allQuestions, setAllQuestions] = useState([]);
    const [resources, setResources] = useState([]);
    const [topics, setTopics] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const prevAnswers = _.get(props, 'location.state.answers', {});
    const doneTopics = _.get(props, 'location.state.doneTopics', []);
    const {type, challenge, participant} = _.get(props, 'location.state', {});
    console.log("Recovered answers: ", prevAnswers)
    console.log("Recovered done topics: ", doneTopics)
    const [answers, setAnswers] = useState({ ...prevAnswers });
    const [finish, setFinish] = useState(false);
    const [loading, setLoading] = useState(false);
    const [startTime] = useState(new Date());
    const completedExam = topics.every(topic => topicId == topic.id.toString() || doneTopics.includes(topic.id.toString()))
    // console.log(topics.map(topic => doneTopics.includes(topic.id.toString()))+ ' who dis')
    // console.log(completedExam ? "Amemaliza" : "thomo")

    useEffect(() => {
        setLoading(true)
        api.get(endpoints.subjectExamQuestions(yearId))
            .then(response => {
                setLoading(false);
                let _questions = response.data.questions.sort((a, b) => a.question_number - b.question_number);
                setAllQuestions(_questions)
                if (!!topicId) {
                    _questions = _questions.filter(q => _.get(q, 'topic.id') == topicId)
                }

                console.log(_questions)
                const question_ids = _questions.map(qs => ({ id: qs.id, textResource: qs.text_resource }))
                const _resources = response.data.text_resources.map(rs => ({ ...rs, questions: question_ids.filter(qs => qs.textResource === rs.id).map(({ id }) => id) }));

                const __topics = response.data.questions.map(qt => qt.topic)
                const _topics = _.uniqBy(__topics, 'id').filter(topic => topic !== null)

                if (!!topicId) {
                    const _topic = _topics.find(tp => tp.id == topicId);
                    if (!!_topic) {
                        setTopic(_topic);
                        setYear(_topic.title);
                    }
                } else {
                    setYear(response.data.name);
                }

                setTopics(_topics);
                setQuestions(_questions);
                if (_questions.length === 1) {
                    setFinish(true);
                }
                setResources(_resources);


                console.log(_topics + ' meh');
                console.log(props);

            })
        return () => {
            setCurrentQuestion(0);


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yearId, topicId])


    const nextQuestion = () => {
        if (currentQuestion + 2 === questions.length) {
            setFinish(true);
        }
        if (currentQuestion + 1 >= questions.length) {
            return;
        }

        setCurrentQuestion(currentQuestion + 1);



    }

    const previousQuestion = () => {
        if (currentQuestion + 1 === questions.length) {
            setFinish(false);
        }

        if (currentQuestion === 0) {
            return;
        }
        setCurrentQuestion(currentQuestion - 1);

    }

    const answerQuestion = (question, choice) => {
        setAnswers({ ...answers, [question.id]: choice });
        if (currentQuestion + 1 !== questions.length) {
            const next = () => setCurrentQuestion(currentQuestion + 1);
            setTimeout(next, 300);
        }
        if (currentQuestion + 1 === questions.length) {
            setFinish(true);
            // if(!completedExam){
            //     const next = () => {
            //         props.history.push({
            //             pathname:"/results",
            //             state:{
            //                 completedExam: completedExam,
            //                 questions: (completedExam || !topicId) ? allQuestions : questions,
            //                 topic: topic,
            //                 topics: topics,
            //                 answers: answers,
            //                 topicId: topicId,
            //                 examId: yearId,
            //                 subjectId: subjectId,
            //                 doneTopics: doneTopics,
            //                 startTime,
            //                 endTime: new Date()
            //             }
            //         });
            //     };
            //     setTimeout(next, 300);
            // }
        }
    }


    let question = questions ? questions[currentQuestion] : {};
    let answer = null;
    if (questions !== null && question !== null) {
        answer = answers[question.id]
    }

    // Tests to check for close text and paragraph
    let is_close_test = false;
    const resource = resources.find(rs => rs.questions.includes(question.id));
    if (resource) {
        is_close_test = resource.statement.indexOf(`__${currentQuestion + 1}__`) !== -1
    }
    const currentTopic = question ? question.topic ? question.topic : null : null;

    return (

        <>
            <div className='hold-both'>
                <Prompt when={Object.keys(answers).length > 0 && !finish} message={`Are you sure you want to lose your progress on ${year} exam?`} />
                <div className="q-wrapper">
                    {topicId && (
                        <div className="topic-view">
                            <p className="placeholder">Topic:</p>
                            <p className="api-topic">{_.get(topic, 'title')}</p>
                        </div>
                        
                    )}
                    <div className="questions-section">
                        {questions && (resource ? is_close_test ? (
                            <CloseText
                                question={question}
                                year={year}
                                answer={answer}
                                answers={answers}
                                resource={resource}
                                questions={questions}
                                answerQuestion={answerQuestion}
                                currentQuestion={currentQuestion}
                                setCurrentQuestion={setCurrentQuestion}
                                numberOfQuestions={questions.length}
                            />
                        ) : (
                            <TextQuestion
                                question={question}
                                year={year}
                                answer={answer}
                                answers={answers}
                                resource={resource}
                                questions={questions}
                                answerQuestion={answerQuestion}
                                currentQuestion={currentQuestion}
                                setCurrentQuestion={setCurrentQuestion}
                                numberOfQuestions={questions.length}
                            />
                        ) : (
                            <ChoicesQuestion
                                question={question}
                                currentQuestion={currentQuestion}
                                numberOfQuestions={questions.length}
                                year={year}
                                answer={answer}
                                answerQuestion={answerQuestion}
                            />
                        ))}
                    </div>
                    <div className="mbelenyums">
                    <button id="prev" onClick={previousQuestion}><i class="fa fa-chevron-left"></i>   Prev question</button>  
                    {!finish ? (
                        <button id="nxt" onClick={nextQuestion}>Next question   <i class="fa fa-chevron-right"></i></button>
                    ) : (
                        <Link  to={{
                            pathname: "/results",
                            state: {
                                completedExam: completedExam,
                                questions: (completedExam || !topicId) ? allQuestions : questions,
                                topic: topic,
                                topics: topics,
                                answers: answers,
                                topicId: topicId,
                                examId: yearId,
                                subjectId: subjectId,
                                doneTopics: doneTopics,
                                startTime,
                                endTime: new Date(),
                                // pass info from leaderboard component
                                type, challenge, participant
                            }
                        }}><button className="kwinish">Finish</button></Link>
                    )}
                    </div>

                </div>
                <div className="nxt-prev">
                    
                    <div className="plug">
                        <span>©&nbsp;The Kenya National Examination Council</span></div>
                        <div className="plug">
                        <span>©&nbsp;Copyright Prep 2021. Powered by Elimu  </span></div>
                    </div>
            </div>
        </>
    )
}
