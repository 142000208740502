const BASE_URL = 'https://api.exams.old.e-limu.org/exams';
 
export default {
    'user': [BASE_URL, 'exam-user'].join('/'),
    'subjects': [BASE_URL, 'api/subjects', ''].join('/'),
    'years': id => [BASE_URL, 'api/subject-exams', id, ''].join('/'),
    'subscriptions': [BASE_URL, 'api/all-subscriptions', ''].join('/'),
    'confirmPayment': [BASE_URL, 'api/confirm-payment', ''].join('/'),
    'buyAllExams': [BASE_URL, 'api/buy-all-exams', ''].join('/'),
    'examSolutions': id => [BASE_URL, 'api/exam-solutions', id, ''].join('/'),
    'login': [BASE_URL, 'api/login', ''].join('/'),
    'register': [BASE_URL, 'api/register-v2', ''].join('/'),
    'transactions': [BASE_URL, 'api/transactions', ''].join('/'),
    'profile': [BASE_URL, 'api/profile', ''].join('/'),
    'saveResults': [BASE_URL, 'api/save-results', ''].join('/'),
    'subjectExamQuestions': id => [BASE_URL, 'api/subject-exam-questions', id, ''].join('/'),
    'studentResults':[BASE_URL, 'api/all-student-results',''].join('/'),
}