import React, { useState } from 'react';
import '../../css/Signup.css';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser, signUpUser } from '../../redux/actions/authentication';
import mapAuthenticationStateToProps from './authStateToProps';


const Signup = (props) => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [referalCode, setReferalCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { signUpUser, loggedIn, error } = props;
  if (loggedIn) {
    return (
      <Redirect to="/" />
    )
  }
  let signUpError;
  if (error) {
    if (error.context === 'signUp') {
      signUpError = error.message;
    }
  }

  const postSignUp = (e) => {
    e.preventDefault();
    const body = {
      "username": userName,
      "password": password,
      "first_name": firstName,
      "last_name": lastName,
      "email": email,
      "phone_number": phoneNumber
    };
    signUpUser(body);

    // api.post(endpoints.register, body)
    //    .then(response => {
    //       console.log(response)
    //       if(response.status===201){
    //         const token = response.data.token;
    //         window.localStorage.setItem("token",token);
    //         headHome()
    //       }
    //   })
  }



  return (
    <div className="FormCenter">
      <div className="login-info">
        <section className="login-section">
          <div className="section4-wrapper">
            <div className="sec4left">
              <div className="sec4subheading">
                <p className="testimonials">
                  What we do
                </p>
              </div>
              <div className="login-sec-heading">
                <div className="logsection4head">
                  <h4 className="logsec4head">
                    We help students succeed
                  </h4>
                </div>

                <div className="testimony">
                  <p>We take your success very seriously and that is why our system is designed to help you achieve it.</p>
                </div>

                <div className="testdirection">
                  <p className="tdirection"> Let’s sign up and get started.</p>
                </div>

              </div>
            </div>
            <div className="sec4right">
              <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/anothergirl.png" alt="sec4 img" className="sec4rightimg" />
            </div>
          </div>
        </section>
      </div>
      <form id='form' onSubmit={postSignUp}>

        <div className="form-wrapper">

          <div className="FormField">
            <label for="Firstname" className="form-label">First Name</label>
            <input type="text" id="name" class="form-control" placeholder="Enter your first name" name="name" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} required ></input>
          </div>

          <div className="FormField">
            <label for="Lastname" className="form-label">Last Name</label>
            <input type="text" id="name" class="form-control" placeholder="Enter your last name" name="name" value={lastName} onChange={(e) => { setLastName(e.target.value) }} required></input>
          </div>

          <div className="FormField">
            <label for="Username" className="form-label">User Name</label>
            <input type="text" id="name" class="form-control" placeholder="Enter a Username" name="name" value={userName} onChange={(e) => { setUserName(e.target.value) }} required></input>
          </div>

          <div className="FormField">
            <label for="Phonenumber" className="form-label">Phone Number</label>
            <input type="phonenumber" id="number" class="form-control" placeholder="Enter your phone number" name="phone number" value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} required ></input>
          </div>

          <div className="FormField">
            <label for="Email" className="form-label">Email</label>
            <input type="email" id="email" class="form-control" placeholder="Enter your email" name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} required ></input>
          </div>

          <div className="FormField">
            <label for="referalCode" className="form-label">referalCode</label>
            <input type="text" id="name" class="form-control" placeholder="Enter referal code if any" name="name" value={referalCode} onChange={(e) => { setReferalCode(e.target.value) }} ></input>
          </div>

          <div className="FormField">
            <label for="Password" className="form-label">Password</label>
            <input type="password" id="password" class="form-control" placeholder="Enter your password" name="password" value={password} onChange={(e) => { setPassword(e.target.value) }} required ></input>
          </div>

          <div className="FormField">
          <label for="Password" className="form-label">Password</label>
            <input type="password" id="password" class="form-control" placeholder="Confirm your password" name="password" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} required ></input>
          </div>
          <p style={{ color: "red" }}>{signUpError}</p>
          <input className="FormField__Button mr-20" type="submit" value="Sign up" /> <Link to="/login" className="FormField__Link">I'm already a member</Link>
        </div>
      </form>
    </div>
  )
}

export default connect(mapAuthenticationStateToProps, { getUser, signUpUser })(Signup);