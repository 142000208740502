import {AUTH_LOGIN,AUTH_GET_USER, AUTH_LOGIN_FAIL,
    AUTH_GET_USER_FAIL, AUTH_LOGOUT, AUTH_REQUEST_SENT,
     AUTH_REQUEST_DONE, AUTH_SIGNUP, AUTH_SIGNUP_FAIL} from '../actions/action-types';
const initialState = {
    loggedIn:false,
    user:null,
    error: false,
    loading: false,
    signUpSuccess:false,
}

const authReducer = (state=initialState, action)=>{
    switch(action.type){
        case AUTH_LOGIN:
            return {
                ...state,
                loggedIn:true,
            }
        case AUTH_LOGIN_FAIL:
            return {
                ...state,
                loggedIn:false,
                error:action.error
            }
        case AUTH_GET_USER:
            return {
                ...state,
                loggedIn:true,
                user:action.user
            }
        case AUTH_GET_USER_FAIL:
            return {
                ...state,
                user:false,
                loggedIn:false,
                error:action.error
            }
        case AUTH_REQUEST_SENT:
            return {
                ...state,
                loading:true
            }
        case AUTH_REQUEST_DONE:
            return {
                ...state,
                loading:false
            }
        case AUTH_LOGOUT:
            return {
                ...state,
                user:false,
                loggedIn:false,
                error:false
            }
        case AUTH_SIGNUP:
            return {
                ...state,
                loggedIn:true,
                signUpSuccess:true,
            }

        case AUTH_SIGNUP_FAIL:
            return {
                ...state,
                error:action.error,
                signUpSuccess:false,
            }
        default:
            return state;
    }
}
export default authReducer;
