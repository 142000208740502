import React, {useState, useEffect} from 'react';
import api from "../../api";
import Pagination from '../Years/Pagination';
import {Link} from "react-router-dom";
import moment from 'moment';
import '../../css/Selecttopic.css'; 
import endpoints from "../../endpoints";
import {parse, stringify} from "querystring-es3";
import PaymentModal from "../Years/PaymentModal";

export const Selecttopic = (props) => {

    const { match, location, history} = props;

    const [items, setItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscriptionsError, setSubscriptionsError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage] = useState(7);

    // fetches data from the api and converts it to json
    const fetchYears = () => {
        setLoading(true);
        api.get(endpoints.years(match.params.id), {params: {format: 'json'}})
            .then(response => response.data)
            .then(data => {
                setLoading(false);
                setItem(data)
            })
    }

    // fetches all subscribed exams
    const fetchSubscriptions = (load=false) => {
        if (load){
            setLoading(true);
        }
        api.get(endpoints.subscriptions, {params: {format: 'json'}})
            .then(res => {
                if(res.status === 200){
                    setSubscriptions(res.data)
                } else {
                    setSubscriptionsError(true)
                }
                if (load){
                    setLoading(false);
                }
            })
            .catch(e => {
                setSubscriptionsError(true)
                if (load){
                    setLoading(false);
                }
            })
    }

    // on component mount gets both
    useEffect(() => {
        fetchYears();
        fetchSubscriptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.id]);

    // Get current posts
    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    const currentPost = items.slice(indexOfFirstPost, indexOfLastPost);

    //Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    // modal stuff
    const qs_params = parse(location.search.replace(/^\?/, ''));
    const modalOpen = Object.keys(qs_params).includes('payment')

    const closeModal = () => {
        const copy = {...qs_params};
        delete copy['payment'];
        history.replace({search: stringify(copy)})
    }

    const showModal = () => {
        history.replace({search: stringify({...qs_params, 'payment': true})})
    }

    return (
        <>
            <div className="maain">
                <div className="everything-else">
                    <div className="subject-title">
                        <h4>subject by Topics</h4>
                    </div>

                    Fetching Topic groups...
                    <div className="years-section">
                        {loading ? <h2>Loading...</h2> : null}
                        {currentPost.map(item => {
                            const activeSubscription = subscriptions.find(sub => {
                                return (sub.exam === item.id) && (new Date(sub.end_time) >= new Date());
                            });

                            if (activeSubscription){
                                return (
                                    
                                        <Link key={item.id} to={{"pathname":`/subjects/${match.params.id}/selecttopic/${item.id}`,
                                        "state":{
                                            questions:item.questions,
                                            textResources:item['text_resources'],
                                            year:item.name,
                                            examId: item.id,
                                            subjectId: match.params.id
                                        }
                                            }} className="year-card" >
                                                
                                    <div className="singleyear">
                                        <h6 className = "itm-name">{item.name}</h6> 
                                        
                                        
                                                        <div className="subscription-year">
                                            <b>expires {moment(activeSubscription.end_time).fromNow()}</b>
                                        </div>
                                        </div>
                                        </Link>
                                    
                                    
                                )
                            } else {
                                return (
                                    <span onClick={() => showModal(item.id)} className="nt-sub-singleyear nt-sub" key={item.id} >
                                        <h6 className="nt-su-txt">{item.name}</h6>
                                        <i id="lock" className="fa fa-lock" aria-hidden="true"/>
                                    </span>
                                )
                            }

                        })}
                    </div>
                    <div className="pagination-section">
                        <Pagination postsPerPage={postPerPage} totalPosts={items.length} paginate={paginate} />
                    </div>
                </div>
                {/* modal popup */}
                {modalOpen && <PaymentModal close={closeModal} year={modalOpen} reloadSubscriptions={fetchSubscriptions} />}
            </div>
        </>
    )
}


