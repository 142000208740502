import React from "react";
import "../../css/Payment.scss";
import {connect} from "react-redux";
import endpoints from "../../endpoints";
import api from "../../api";

const PAYMENT_OPTIONS = [
    {
        "cost": 50,
        "message": "all exams for a week",
        "id": 6
    },
    {
        "cost": 180,
        "message": "all exams for a month",
        "id": 4
    },
    {
        "cost": 400,
        "message": "all exams for 3 months",
        "id": 5
    },
    {
        "cost": 1000,
        "message": "all exams for a year",
        "id": 7
    }
]


function Loading({message='Loading...'}) {
    return (
        <div className='payment-loading'>
            <div className='loader'/>
            <div className='message'>{message}</div>
        </div>
    )
}



export default connect(state => state.authentication)(function (props) {
    const {close, user, loading: userLoading, reloadSubscriptions} = props;

    // Get user balance from redux, default to 0
    const balance = (user ? user.balance : 0) || 0;

    const [error, setError] = React.useState(null);
    const [buyError, setBuyError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [plan, setPlan] = React.useState(PAYMENT_OPTIONS[0].id);

    const plan_amount = PAYMENT_OPTIONS.find(o => o.id === plan).cost;

    const processPayment = event => {
        event.preventDefault();

        // Simple way of serializing the form, all fields in the form with be serialized to the object below
        const data = {};
        for(const element of event.target.elements){
            if(element.nodeName === "INPUT"){
                data[element.name] = element.value
            }
        }

        const purchaseExam = () => {
            // Function to purchase exam according to plan, referenced below
            api.post(endpoints.buyAllExams, {payment_plan: plan})
                .then(response => {
                    setLoading(false)
                    reloadSubscriptions(true)
                    close()
                }, error => {
                    setLoading(false);
                    if(error.response && error.response.data){
                        setBuyError(error.response.data.error);
                    } else {
                        setBuyError("Error: " + error.message)
                    }
                })
        }

        setLoading(true);
        // In the case the user has enough money on his account, the input field for code won't appear here
        if(Object.keys(data).includes('code')){
            // User didn't have money, report code to backend before purchasing exams.
            api.post(endpoints.confirmPayment, {transaction_code: data.code})
                .then(response => {
                    // All is good, proceed to purchase exam
                    purchaseExam();
                }, error => {
                    // Code may have an issue
                    if(error.response && error.response.data){
                        setError(error.response.data.error);
                    } else {
                        setError("Error: " + error.message)
                    }
                    setLoading(false)
                })
        } else {
            // User already had money, just purchase exam
            purchaseExam();
        }
    }

    return (
        <div className="bg-modal">
            <div className="modal-content">
                {(loading || userLoading) && <Loading message={user ? 'Confirming Payment...' : 'Checking Balance...'}/>}
                <div className="close" id="close" onClick={close}>+</div>
                <h3>Choose a subscription</h3>

                <div className="payment-dropdown">
                    <select value={plan} onChange={e => setPlan(parseInt(e.target.value))}>
                        {PAYMENT_OPTIONS.map(o => <option value={o.id} key={o.id}>Buy {o.message} @ Ksh.{o.cost}</option>)}
                    </select>
                </div>

                <form onSubmit={processPayment}>
                    {balance >= plan_amount ? (
                        <ul className="payment-steps">
                            <li>Your account balance is <strong>Ksh. {balance}</strong></li>
                            <li><strong>Ksh.{plan_amount}</strong> will be deducted from your account</li>
                        </ul>
                    ):(
                        <ul className="payment-steps">
                            <li>Go to Buy Goods and Services, enter Till Number <strong>787 499</strong></li>
                            <li>Enter amount: <strong>Ksh. {balance ? plan_amount - balance : plan_amount}</strong> {balance > 0 && <span>(Your current balance: Ksh. {balance})</span>}</li>
                            <li>
                                After payment, type the transaction code below.
                                <br/>
                                <br/> 
                                <span className="wani">Be careful not to mix up your 1s and Is and 0s and Os in the transaction!</span>
                                <br/>
                                <input className="till-put" type="text" required name="code" placeholder="Enter Confirmation Code" />
                                {error && <div className="code-error">{error}</div>}
                            </li>
                        </ul>
                    )}
                    <div className="pay">
                        {buyError && <div className="code-error">{buyError}</div>}
                        <button className="pay-btn" type="submit">
                            Buy Exams
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
})
