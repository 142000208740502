import React, { useState, useEffect } from 'react';
import '../../css/Leaderboard.css';
import Modal from 'react-modal';
import { IconContext } from "react-icons";
import { BiFilter } from 'react-icons/bi';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import axios from 'axios';
import store from '../../redux/store';
import _ from 'lodash';
import moment from 'moment';
import { tournamentApi } from "../../api"


Modal.setAppElement(document.getElementById('root'));

export default function Leaderboard() {

    const [JoinModalIsOpen, setJoinModalIsOpen] = useState(false);
    const [challengeData, setChallengeData] = useState([]);
    const [tournamentData, setTournamentData] = useState([]);
    const [tournamentResultData, setTournamentResultsData] = useState([]);

    const [selectedUserTornamentView, setUserTornamentView] = useState(null);
    const [challengeResultsData, setChallengeResultsData] = useState([]);
    const [searchTournament, setSearchTournament] = useState('');

    const authStore = store.getState();
    // Will be created on page load
    const [participant, setParticipant] = useState({});

    React.useEffect(() => {
        console.log(authStore)
        const userId = _.get(authStore, 'authentication.user.id', null);
        const userName = _.get(authStore, 'authentication.user.username', null);
        if (userId == null) return;
        tournamentApi.get('/participants', { params: { 'filters[userid]': userId } }).then(res => {
            if (res.data.data.length > 0) {
                setParticipant(res.data.data[0]);
            } else {
                tournamentApi.post('/participants', { data: { userid: userId, name: userName } }).then(res => {
                    setParticipant(res.data.data);
                })
            }
        })
    }, [_.get(authStore, 'authentication.user.id', null)]);


    const fetchData = () => {
        const challengeAPI = '/challenges?populate=tournament';
        const challengeResultsAPI = '/challenge-results?populate=challenge&filters[participant]=' + participant.id;
        const tournamentResultsAPI = '/tournament-results';

        const getChallenges = tournamentApi.get(challengeAPI);
        const getChallengeResults = tournamentApi.get(challengeResultsAPI);
        const getTournamentResults = tournamentApi.get(tournamentResultsAPI);

        axios.all([getChallenges, getChallengeResults, getTournamentResults]).then(axios.spread((...responses) => {
            const allchallengeData = responses[0].data.data;
            const allchallengeResultsData = responses[1].data.data;
            const alltournamentResultsData = responses[2].data.data;

            const newChallenges = allchallengeData.map(challenge => ({
                ...challenge,
                results: allchallengeResultsData.find(challengeResult => _.get(challengeResult, 'attributes.challenge.data.id', '') === challenge.id)
            }))

            setChallengeData(newChallenges);
            setTournamentResultsData(alltournamentResultsData);
        }
        ))
    }


 
    useEffect(() => {
        if (participant.id == null) return;
        fetchData();
    }, [participant.id])


    useEffect(() => {
        const tournamentId = _.get(selectedUserTornamentView, 'tournament.id', null);
        const userId = _.get(selectedUserTornamentView, 'user.id', null);
        if (tournamentId == null || userId == null) return;

        // fetch tournament challenge results for selected user
        const chResults = tournamentApi.get('/challenge-results', { params: { 'filters[participant]': userId, 'filters[challenge[tournament]]': tournamentId, populate: 'challenge' } });
        const challenges = tournamentApi.get('/challenges', { params: { 'filters[tournament]': tournamentId } })
        axios.all([chResults, challenges]).then(res => {
            const chResults = res[0].data.data;
            const challenges = res[1].data.data;

            const challengesResult = challenges.map(challenge => ({
                ...challenge,
                results: chResults.find(challengeResult => _.get(challengeResult, 'attributes.challenge.data.id', '') === challenge.id)
            }))
            setChallengeResultsData(challengesResult);
        })
    }, [_.get(selectedUserTornamentView, 'tournament.id', null), _.get(selectedUserTornamentView, 'user.id', null)])

    // this function adds data to the challenge popup modal and opens it
    const openTournamentChallengeModal = (tournament, user) => {
        setUserTornamentView({ tournament, user });
    }



    return (
        <>

            <Modal isOpen={JoinModalIsOpen}
                style={
                    {
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.5)'
                        },
                    }
                }>
                <div className="modal-main">
                    <button className='close-me' onClick={() => setJoinModalIsOpen(false)}>X close</button>

                    <div className="leaderboard-header">
                        <div className="lhh">
                            <h3>Ace Learning Challenge</h3>
                            <h6 id='onlythisone'>Welcome to this month's challenge</h6>
                        </div>

                    </div>

                    <div className="table-section">
 
                        <table>

                            <thead>
                                <tr>
                                    <th scope="col">Week</th>
                                    <th scope="col">Name of Challenge</th>
                                    <th scope="col">Days Remaining</th>
                                    <th scope="col">status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>



                                {challengeData.map(challenge => (
                                    <tr key={challenge.id}>
                                        <td data-label="Week">{challenge.attributes.week_number}</td>
                                        <td data-label="Name of Challenge">{challenge.attributes.name_of_challenge}</td>
                                        <td data-label="Days remaining">{moment(challenge.attributes.end).diff(moment(), 'days')} days</td>
                                        <td data-label="status">
                                            {challenge.results === undefined ? (
                                                <h6 className='not-part'>Not Participated</h6>
                                            ) : (
                                                <h6 className='yes-part'>Participated</h6>
                                            )}
                                        </td>
                                        <td data-label="Action">


                                            <Link to={{ pathname: challenge.attributes.link_to_challenge, state: { type: "challenge", challenge, participant } }}>
                                                {challenge.results !== undefined || moment(challenge.attributes.start) < moment() && moment(challenge.attributes.end) > moment() &&(
                                                    <button className='challenge'>Join</button>
                                                )}
                                            </Link>
                                        </td>
                                    </tr>
                                ))}



                            </tbody>
                        </table>

                    </div>


                </div>
            </Modal>

            {/* // this modal is for the view details of the tournament leaderboard */}
            <Modal isOpen={selectedUserTornamentView !== null}
                style={
                    {
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.5)'
                        },
                    }
                }>

                <div className="modal-main">
                    <button className='close-me' onClick={() => setUserTornamentView(null)}>X close</button>

                    <div className="lhh">
                        <h3>{_.get(selectedUserTornamentView, 'user.name', '')}'s Details</h3>
                        <h6 id='onlythisone'>current tournament ranking and activity.</h6>
                    </div>

                    <div className="table-section">

                        <table>

                            <thead>
                                <tr>
                                    <th scope="col">Week</th>
                                    <th scope="col">Name of Challenges</th>
                                    <th scope="col">score</th>
                                    <th scope="col">Percentage</th>
                                    <th scope="col">Duration</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {challengeResultsData.map(challenge => (
                                    <tr key={challenge.id}>
                                        <td data-label="Week">{challenge.attributes.week_number}</td>
                                        <td data-label="Name of Challenge">{challenge.attributes.name_of_challenge}</td>
                                        {challenge.results === undefined ? (<>
                                            <td data-label="Score">-</td>
                                            <td data-label="Percent">-</td>
                                            <td data-label="Duration">-</td>
                                            <td data-label="status"><h6 className='not-part'>Not Participated</h6></td>
                                        </>) : (<>
                                            <td data-label="Score">{challenge.results.attributes.correct} / {challenge.results.attributes.correct + challenge.results.attributes.failed}</td>
                                            <td data-label="Percent">{challenge.results.attributes.percentage} %</td>
                                            <td data-label="Duration">{parseFloat(challenge.results.attributes.duration / 60).toFixed(2)} min</td>
                                            <td data-label="status"><h6 className='yes-part'>Participated</h6></td>
                                        </>)}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>


                </div>

            </Modal>


            <div className="leaderboard-main">

                <div className="lhh">
                    <h3>Ace Learning Tournament</h3>
                    <h6 id='onlythisone'>current tournament ranking and activity.</h6>
                </div>
                <div className="leaderboard-header">

                    <div className="lbh">
                        <button className="challenge" onClick={() => setJoinModalIsOpen(true)}>Join Tournaments</button>
                    </div>


                    <div className="sby">
                        <div className="input-group mb-3">
                            <input type="text" onChange={event => { setSearchTournament(event.target.value) }} className="form-control" placeholder='Search name or tournament...' id="basic-url" aria-describedby="basic-addon3" />
                        </div>
                    </div>

                   

                    <div className="sbd">
                        <div className="input-group mb-3">
                            <button className="filter-btn"><BiFilter /> by Ranking</button>
                        </div>
                    </div>

                </div>

                <div className="table-section">

                    <table>

                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Name of Tournament</th>
                                <th scope="col">Ranking</th>
                                <th scope="col">Avg.Score</th>
                                <th scope="col">Avg. Time</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {tournamentResultData.filter((result) => {
                                if (searchTournament == '') {
                                    return result;
                                }else if (result.participant.name.toLowerCase().includes(searchTournament.toLowerCase()) || result.tournament.tournament_name.toLowerCase().includes(searchTournament.toLowerCase())) {
                                    return result;
                                }
                            }).map(result => (

                                <tr key={result.id}>

                                    <td data-label="Name">{result.participant.name}</td>
                                    <td data-label="Name of Tournament">{result.tournament.tournament_name}</td>
                                    <td data-label="Rating">{result.rank}
                                        {/* <IconContext.Provider value={{ className: 'up-green' }}>
                                            <BsArrowUp />
                                        </IconContext.Provider> */}
                                    </td>
                                    <td data-label="Ave.Score">{result.avg_score}</td>
                                    <td data-label="Ave.Time">{parseFloat(result.avg_time / 60).toFixed(2)} Min</td>
                                    <td data-label="Action"><button className='to-single' id='single-link' onClick={() => openTournamentChallengeModal(result.tournament, result.participant)}>View Details</button></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>

                </div>


            </div>
            <div className="nxt-prev">

                <div className="plug">
                    <span>©&nbsp;The Kenya National Examination Council</span></div>
                <div className="plug">
                    <span>©&nbsp;Copyright Prep 2021. Powered by Elimu  </span></div>
            </div>

        </>
    )
}
