import React, {useState} from 'react';
import '../../css/Header.css';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser } from '../../redux/actions/authentication';
import mapAuthenticationStateToProps from '../Auth/authStateToProps';

Modal.setAppElement('#root');
const Header = (props) => {

  const [redirectModalIsOpen, setRedirectModalIsOpen] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const { user, loggedIn, getUser } = props;
  if (user == null) {
    getUser();
  }

  const handleClick = () => {
    setRedirecting(true);
    setTimeout(() => {
      window.location.href = 'https://play.e-limu.org/auth/login?next=/dashboard';
    }, 5000);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)'
    }
    },
  };

  return (
    <>
    <Modal isOpen={redirectModalIsOpen} style={customStyles}>
    <button className='close-me' onClick={() => setRedirectModalIsOpen(false)}>X close</button>
    <div className="redirect-modal-main">
      <div className="red-cont">
        <div className="img-holder">
        <img className='logo-logo' src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/loading-opt2.gif" alt="loading" />
        </div>
      <h4 className="red-heading">Hello there!</h4>
      <p className="red-desc">We are taking you to our new website with better experience and<br></br> access to more amazing products!</p>
      <a id='logreg' href="https://play.e-limu.org/auth/login?next=/dashboard"> <button className='logreg'>Go Now</button></a>
      </div>
      </div>

    </Modal>
    <div className="main">
      <div className="wrapper">
        <div className="logo">
          <Link to="/"><img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/preplogo.png" alt="prep" className="logo-title" />
          </Link>
        </div>
        <div className="menu">

          <nav>
            {loggedIn ? (<div className="desk-nav">
              <ul id='desko'>
                <Link id='shaa' to="/subjects"><strong><li><i aria-hidden="true"></i>Subjects</li></strong></Link>
                <Link id='shaa' to="/leaderboard"><strong><li><i aria-hidden="true"></i>LeaderBoard</li></strong></Link>
                <Link id='shaa' to="/paymentplan"><strong><li><i aria-hidden="true"></i>Pricing</li></strong></Link>
                <Link id='shaa' to="/study-guide"><strong><li><i aria-hidden="true"></i>Study guide</li></strong></Link>
                <Link id='shaa' to="/logout"><strong><li ><i aria-hidden="true"></i>Logout</li></strong></Link>
                <Link id='shaa' to="/contactus"><strong><li><i aria-hidden="true"></i>Contact Us</li></strong></Link>
              </ul>
            </div>) : (
              
              <div  onClick={handleClick}><button className='logreg' onClick={() => setRedirectModalIsOpen(true)}>Register/Login</button></div>
            )
            }
            <div className="dropdown">
              <button className='burger-btn'>
                <i className="fa fa-bars fa-2x" id="buger" aria-hidden="false"></i>
              </button>
              {
                loggedIn ? (
                  <ul>
                    <Link id='shaa' to="/subjects"><strong><li><i aria-hidden="true"></i>Subjects</li></strong></Link>
                    <Link id='shaa' to="/leaderboard"><strong><li><i aria-hidden="true"></i>LeaderBoard</li></strong></Link>
                    <Link id='shaa' to="/paymentplan"><strong><li><i aria-hidden="true"></i>Pricing</li></strong></Link>
                    <Link id='shaa' to="/study-guide"><strong><li><i aria-hidden="true"></i>Study guide</li></strong></Link>
                    <Link id='shaa' to="/logout"><strong><li ><i aria-hidden="true"></i>Logout</li></strong></Link>
                    <Link id='shaa' to="/contactus"><strong><li><i aria-hidden="true"></i>Contact Us</li></strong></Link>
                  </ul>
                ) : (
                  <button className='logreg' onClick={() => setRedirectModalIsOpen(false)}>Register/Login</button>
                )
              }
            </div>
          </nav>


        </div>
      </div>
    </div>
    </>
  )
}



export default connect(mapAuthenticationStateToProps, { getUser })(Header);