import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {logOutUser} from '../../redux/actions/authentication';

const LogOut = (props)=>{
    const {logOutUser} = props;
    logOutUser();
    return (
        <Redirect to='/'/>
    )
}
export default connect(null, {logOutUser})(LogOut);