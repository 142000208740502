import React, { Component,useState } from 'react';
import '../../css/Preplanding.css';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import Item from './Item';

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 }
];

const hidePromotion = () => {
    const promotion = document.getElementById('hide-prom-lead');
    promotion.style.display = 'none';
}

Modal.setAppElement('#root');
export default function Preplanding() {

    const [redirectModalIsOpen, setRedirectModalIsOpen] = useState(false);
    const [redirecting, setRedirecting] = useState(false);

    const handleClick = () => {
        setRedirecting(true);
        setTimeout(() => {
          window.location.href = 'https://play.e-limu.org/auth/login?next=/dashboard';
        }, 5000);
      };

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)'
        }
        },
      };

    return (
        <>
        <Modal isOpen={redirectModalIsOpen} style={customStyles}>
        <button className='close-me' onClick={() => setRedirectModalIsOpen(false)}>X close</button>
    <div className="redirect-modal-main">
      <div className="red-cont">
      <div className="img-holder">
        <img className='logo-logo' src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/loading-opt2.gif" alt="loading" />
        </div>
      <h4 className="red-heading">Hello there!</h4>
      <p className="red-desc">We are taking you to our new website with better experience and<br id='break'></br> access to more amazing products!</p>
      <a id='logreg' href="https://play.e-limu.org/auth/login?next=/dashboard"> <button className='red-btn'>Go Now</button></a>
      </div>
      </div>

    </Modal>
            {/* <div className="prom-lead" id="hide-prom-lead">
                <p> We just launched a new feature 😃 Check out an exciting way to practice with your friends! on our new <Link to="/leaderboard">Leaderboard section</Link></p>
                <button className="prom-lead-btn" onClick={() => hidePromotion()}>X</button>
            </div> */}
            <div>
                <section className="hero">
                    <div className="hero-wrapper">
                        <div className="hero-left">
                            <div className="hero-heading">
                                <h1 className="prep-heading">
                                    Start your <strong className='orange'>KCPE Exams preparations</strong> here with the best guidance
                                </h1>
                            </div>
                            <div className="hero-description">
                                <p className="prep-description">
                                    Exams are a big part of how your life will be shaped, we help you ace them by providing a set of reliable past questions and answers.
                                </p>
                            </div>
                            <div className="getstart">
                            <span  onClick={handleClick}><button className="getstarted" onClick={() => setRedirectModalIsOpen(true)}>Get started  <i className="fa fa-chevron-right"></i></button></span>
                                <a  className='hide-on-mobile' href="https://play.google.com/store/apps/details?id=com.elimu.exams" target="_blank"> <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/appstore.png" alt="download PREP from playstore" /> </a>
                            </div>
                        </div>
                        <div className="hero-right">
                            <video className='prom-vid' width="501" height="291" poster="https://elimu-c3puzzles.s3.eu-central-1.amazonaws.com/Frame+49.png" controls
                                src="https://elimu-c3puzzles.s3.eu-central-1.amazonaws.com/elimu+prep+promo.mp4" type="video/mp4">
                            </video>
                        </div>
                    </div>
                </section>

                <section className="section2">
                    <div className="first-text">
                        <p className="info-txt">
                            Preparing for your exams.
                        </p>
                        <h4 className="why-choose">
                            Why Choose Prep
                        </h4>
                        <p className="why-description">
                            Improve your knowledge with our set of curriculum based questions and answers. We also provide you with reatime guided results and opportunities to revise and retake your exams.
                        </p>
                    </div>
                    <div className="second-text">

                        <div className="schedule">
                            <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/tick.png" alt="full-access-img" className="full-access-img" />
                            <h5 className="full-access-heading">Expert Breakdown</h5>
                            <p className="full-access-description">Prepare your own timetable and flexibly learn at the convenience of your time.</p>
                        </div>

                        <div className="schedule">
                            <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/tack.png" alt="full-access-img" className="full-access-img" />
                            <h5 className="full-access-heading">Scheduled Learning</h5>
                            <p className="full-access-description">Prepare your own timetable and flexibly learn at the covenience of your time. You can even save and continue later.</p>
                        </div>
                        <div className="schedule">
                            <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/toe.png" alt="full-access-img" className="full-access-img" />
                            <h5 className="full-access-heading">Full accessibility</h5>
                            <p className="full-access-description">We grant you 100% accessibility on all the exam questions from as far back as you want.</p>
                        </div>
                    </div>
                </section>

                <section className="section3">
                    <div className="section3-wrapper">

                        <div className="sec3left">
                            <div className="sec3leftimg">
                                <img className="secleftimg" src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/bgirl.png" alt="sec3img" />
                            </div>
                        </div>

                        <div className="sec3right">
                            <div className="sec3rightsubheading">
                                <p className="wwd">
                                    What We Do
                                </p>
                            </div>
                            <div className="sec3heading">
                                <h4 className="whss">
                                    We help students succeed
                                </h4>
                            </div>
                            <div className="sec3desc">
                                <p className="s3des">
                                    We take your success very seriously and that is why our system is designed to help you achieve it.
                                </p>
                            </div>
                            <div className="sec3extras">
                                <div className="sec3ex1">
                                    <div className="exnum">
                                        <p>120K</p>
                                    </div>
                                    <div className="extex">
                                        <p>Students</p>
                                    </div>
                                </div>
                                <div className="sec3ex1">
                                    <div className="exnum">
                                        <p>8</p>
                                    </div>
                                    <div className="extex">
                                        <p>Subjects</p>
                                    </div>
                                </div>
                                <div className="sec3ex1">
                                    <div className="exnum">
                                        <p>100+</p>
                                    </div>
                                    <div className="extex">
                                        <p>Topics</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section4">
                    <div className="section4-wrapper">
                        <div className="sec4left">
                            
                            <div className="sec4heading">
                               
                                <div className="testimony">
                                    <Carousel breakPoints={breakPoints}>
                                        <Item id='iten'>
                                            
                                            <p>I have used eLimu to do practice exams and revision. I scored 390 in my mocks. I want all kids to be able to do this because it is very easy and you don't struggle a lot. It's the best way to prepare for national exams. I dream that someday the schools will be more interesting and we can use eLimu content to learn.</p>

                                            <div className="testname">
                                            <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/jamaa.png" alt="stars" className="rating" />
                                            </div>
                                            <div className="teststars">
                                                <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/Stars.png" alt="stars" className="rating" />
                                            </div>

                                        </Item>

                                        <Item id='iten'>
                                            <p>My students love pictures and diagrams, learning how to make a photo story was my best lesson. Choosing relevant images, taking pictures and creating a well organized lesson was really interesting. Also being able to cut parts of a video and choosing just the parts my students want.</p>

                                            <div className="testname">
                                            <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/mamaa.png" alt="stars" className="rating" />
                                            </div>
                                            <div className="teststars">
                                            <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/Stars.png" alt="stars" className="rating" />
                                        </div>
                                            
                                        </Item>
                                    </Carousel>
                                </div>

                            </div>
                        </div>
                        
                    </div>

                </section>
            </div>
        </>
    )
}
