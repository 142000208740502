import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {getUser, loginUser} from '../../redux/actions/authentication';
import mapAuthenticationStateToProps from './authStateToProps';


function IsAuthenticated(ComponentToProtect) {
  let component= class extends Component {

    // componentDidMount() {
    //     if(this.props.loggedIn===false){
    //         this.setState({redirect:true})
    //     }
    // }
    constructor(props){
      super(props);
      this.state = {
        redirect:false
      }
    }
    componentDidMount(){
      if(this.props.user===false){
          this.props.getUser();
      }
    }
    render() {
        const loggedIn = this.props.loggedIn;
        const error = this.props.error;
        const loading = this.props.loading;
        if(loggedIn){
            return (
                <React.Fragment>
                <ComponentToProtect {...this.props} />
                </React.Fragment>
            );
      }
      if(error){
        if(error.code===401){
          return <Redirect to="/login" />;
        }
      }
      return null;
    }
  }
  return connect(mapAuthenticationStateToProps,{getUser, loginUser})(component)

}

export default IsAuthenticated;