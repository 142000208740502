import React, { useState, useEffect } from 'react';
import '../../css/Results.css';
import { Link } from 'react-router-dom';
import api, {tournamentApi} from '../../api';
import moment from 'moment';
import endpoints from "../../endpoints";
import _ from "lodash";


export default function Results(props) {

    const [questions, setQuestions] = useState([]);
    const [failedQuestions, setFailedQuestions] = useState([]);
    const [grading, setGrading] = useState({
        correct: 0,
        failed: 0,
        skipped: 0,
        time: 0
    });
    console.log(props.location)
    const { type, challenge, participant, topics, topicId, answers, subjectId, examId, doneTopics = [] } = props.location.state;
    const completedExam = !topicId || props.location.state.completedExam;

    useEffect(() => {
        console.log(props.location.state)
        console.log(completedExam ? "Has completed exam" : "bado")

        const questions = props.location.state.questions.sort((a, b) => a.question_number - b.question_number);
        const answers = props.location.state.answers;
        const startTime = props.location.state.startTime;
        const endTime = props.location.state.endTime;

        let correct = 0;
        let failed = 0;
        let skipped = 0;
        let fq = [];

        for (let question of questions) {
            let answer = answers[question.id]
            if (answer === undefined) {
                skipped += 1
            } else {
                if (answer === question.choices.answer) {
                    correct += 1
                } else {
                    failed += 1;
                    fq.push(question)
                }
            }
        }

        const now = new moment(endTime)
        const timeTaken = now.diff(startTime, 'seconds')

        setQuestions(questions);
        const grade = { correct, failed, skipped, time: timeTaken }
        setGrading(grade);
        setFailedQuestions(fq);

        if (props.location.state && completedExam || type === "challenge") {
            if (type === "challenge") {
                // Post to challenge api
                tournamentApi.post("/challenge-results", {data: {
                    attempt: props.location.key,
                    duration: timeTaken,
                    participant: participant.id,
                    challenge: challenge.id,
                    correct: grade.correct,
                    failed: grade.failed + grade.skipped,
                    percentage: grade.correct / (grade.correct + grade.failed + grade.skipped) * 100,
                }})
                // post tournament results
            } else { 
                const data = {
                    "subject": props.location.state.subjectId,
                    "exam": props.location.state.examId,
                    "marks": correct / questions.length * 100 | 0,
                    "attempt": props.location.key,
                    "time_taken": timeTaken,
                    "topic__title": props.location.state.topic.title,
                    "topic_id": props.location.state.topic.id,
                }
    
                api.post(endpoints.saveResults, data).catch(() => { })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const percentage = grading.correct / questions.length * 100;

    const availableTopics = topics.filter(top => topicId != top.id && !doneTopics.includes(top.id.toString()))
    console.log("Available topics: ", availableTopics)
    const nextTopic = availableTopics.length >= 1 ? availableTopics[0] : null;

    return (
        <>
            <div className="main-results">
                <div className="top-portion">
                    <div className="score">
                        <p id='pinr'><strong>Score :
                        </strong>{grading.correct}/{questions.length}</p>
                        <p><strong>No. of Questions : </strong>{questions.length}</p>
                    </div>
                    <div className="cong-msg-wrapper">
                        <h2 className='cong-msg'>{percentage <= 30 ? 'Nice try' : (percentage < 80 ? 'Keep it up' : 'Excellent')}</h2>
                    </div>
                    <div className="time"> <p id='pinr'><strong>Time Taken</strong> <br /> {moment.utc(grading.time * 1000).format('HH:mm:ss')}</p></div>

                </div>
                <div className="result-section">

                    <div className="correct-answers">
                        <div className="num-corect">
                            <p id='gc'>{grading.correct}</p>
                        </div>
                        <div className="cor-ans-text">
                            <p className="cat">Correct Answers  <i class="fa fa-check-circle"></i></p>
                        </div>
                        <div className="view-cor-ans">
                            <Link to={{
                                pathname: "/allanswers",
                                state: {
                                    questions: questions
                                }
                            }}
                            ><button className="all-ans">view <i class="fa fa-chevron-right"></i></button></Link>
                        </div>
                    </div>

                    <div className="correct-answers percent">
                        <div className="num-percent-wrapper">
                            <h1 className='per'>{percentage | 0}%</h1>
                        </div>
                        <div className="cor-ans-text">
                            <p className="cat">Results</p>
                        </div>
                    </div>

                    <div className="correct-answers incorect">
                        <div className="num-corect">
                            <p id='gc'>{grading.failed}</p>
                        </div>
                        <div className="cor-ans-text">
                            <p className="cat">Incorrect Answers <i class="fa fa-times-circle"></i></p>
                        </div>
                        <div className="view-cor-ans">
                            <Link to={{
                                pathname: "/allanswers",
                                state: {
                                    questions: failedQuestions
                                }
                            }}
                            > <button className="wrong-ans">view <i class="fa fa-chevron-right"></i>    </button></Link>
                        </div>
                    </div>

                </div>

                {completedExam ? (
                    <div className="answer-btns">
                        <div className="revise">
                            <h3>Revise the answers</h3>
                        </div>
                        <div className="r-btn">


                        </div>
                    </div>
                ) : (
                    <div className="continue-to-topic">
                        <p className="cont-p">Continue to next topic</p>
                        <button className="finishbtn">
                            <Link className="finishbtn" to={{
                                pathname: `/subjects/${subjectId}/questions/${examId}/topic/${_.get(nextTopic, 'id', '')}`,
                                state: {
                                    doneTopics: [...doneTopics, topicId],
                                    answers: answers,
                                }
                            }}>Next Topic ({_.get(nextTopic, 'title', '')})</Link>
                        </button>
                    </div>
                )}
                {/* <div className="bottom-portion">
                   <Link to="/perfomance"> <button className="ppt">Perfomance per topic</button></Link>
            </div> */}

            </div>
            <div className="nxt-prev">

                <div className="plug">
                    <span>©&nbsp;The Kenya National Examination Council</span></div>
                <div className="plug">
                    <span>©&nbsp;Copyright Prep 2021. Powered by Elimu  </span></div>
            </div>
        </>
    )
}
