import React, { useState, useEffect } from 'react';
import '../../css/AllAnswers.css';
import endpoints from "../../endpoints";
import api from "../../api";

export default function Allanswers(props) {

    const [questions, setQuestions] = useState([]);
    const [explanationOpen, setExplanationOpen] = useState(true);
    const [explanationsLoading, setExplanationsLoading] = useState(false);
    const [explanations, setExplanations] = useState({});

    useEffect(() => {
        console.log(props);
        if (props.location.state) {
            const { questions, id } = props.location.state;
            setQuestions(questions.sort((a, b) => a.question_number - b.question_number));
            const exam = id || questions[0] ? questions[0].exam : null;
            if (exam) {
                setExplanationsLoading(true)
                api.get(endpoints.examSolutions(exam))
                    .then(response => {
                        setExplanationsLoading(false)
                        setExplanations(response.data.reduce((carry, item) => ({
                            ...carry,
                            [item.question.question_number]: item.explanation
                        }), {}))
                    })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAnswerChoice = (question) => {
        return question.choices['answer'].toUpperCase();
    }

    const getAnswer = (question) => {
        let correctAnswer = question.choices['answer'];
        let choiceHtml = question.choices[correctAnswer];
        return getQuestionOptionHtml(question, correctAnswer);

    }


    const getQuestionHtml = (question) => {
        let questionHtml = question.statement;
        let c = 0;
        questionHtml = questionHtml.replace(/\[resource:\s+(\d+),\s+align:\s+[^\]]+\]/, () => {
            let url = question.image_resources[c].avatar;
            c++;
            return `
            <img src="${url}" />
            `
        })
        return questionHtml
    }

    const getQuestionOptionHtml = (question, choice, position) => {
        let positions = { "A": 0, "B": 1, "C": 2, "D": 3 }
        let choiceHTML = question.choices[choice];
        return choiceHTML.replace(/\[resource:\s+(\d+),\s+align:\s+[^\]]+\]/, () => {
            let url = question.choices.choices_image[positions[choice.toUpperCase()]].image_url;
            return `
            <img src="${url}" />
            `
        })
    }

    const clickedExBtn = () => {
        setExplanationOpen(!explanationOpen)
    }

    return (
        <div className="main-result">
            {
                questions.map((question, i) => (
                    <div className="questions-section" key={i}>
                        {
                            questions &&
                            <div className="wholder">
                                <div className="qyt">
                                    <div className="q-number">
                                        <p><strong>{question.question_number}</strong></p>
                                    </div>


                                </div>
                                <div className="contents" dangerouslySetInnerHTML={{ __html: getQuestionHtml(question) }} />
                                <div className="q-choicess">

                                    <div className="choice-a" id="qc">

                                        <button className="q-btn">
                                            <div className="qa"><p>A.</p></div>
                                            <div className="content" dangerouslySetInnerHTML={{ __html: getQuestionOptionHtml(question, "A", 0) }} /></button>
                                    </div>

                                    <div className="choice-b" id="qc">

                                        <button className="q-btn">
                                            <div className="qa"><p>B.</p></div>
                                            <div className="content" dangerouslySetInnerHTML={{ __html: getQuestionOptionHtml(question, "B", 1) }} /></button>
                                    </div>

                                    <div className="choice-c" id="qc">

                                        <button className="q-btn">
                                            <div className="qa"><p>C.</p></div>
                                            <div className="content" dangerouslySetInnerHTML={{ __html: getQuestionOptionHtml(question, "C", 2) }} /></button>
                                    </div>

                                    <div className="choice-d" id="qc">
                                        <button className="q-btn">
                                            <div className="qa"><p>D.</p></div>
                                            <div className="content" dangerouslySetInnerHTML={{ __html: getQuestionOptionHtml(question, "D", 3) }} /></button>
                                    </div>


                                </div>

                                <br />
                                <br />

                                <div id="qc" className='contento'>
                                    <div className="qa"><p>Correct Answer : {getAnswerChoice(question)}</p></div>
                                    <button id="answer" className="q-btn"><div className="content" dangerouslySetInnerHTML={{ __html: getAnswer(question) }} /></button>
                                </div>

                                <div className="explanations">
                                    <div className="ex-btn-sec">
                                        <button className="ex-btn" onClick={clickedExBtn}>
                                            explanation
                                        </button>
                                    </div>
                                    {explanationOpen ? (
                                        <div className="ex-body">
                                            {explanations[question.question_number] && <h5 dangerouslySetInnerHTML={{ __html: explanations[question.question_number] }} />}
                                            <br></br>
                                            <p>for further explanation,Check out <a href="https://learn.e-limu.org/" target="_blank">eLimu Learn</a>.</p>
                                        </div>
                                    ) : false}

                                </div>

                            </div>

                        }

                    </div>



                ))}
        </div>
    )
}
