import React from "react";
import "../../css/CloseQuestions.scss";
import ChoicesQuestion from "./ChoicesQuestion";


export default function CloseText(props) {
    const {resource, question, numberOfQuestions, year, answer, answerQuestion, currentQuestion, questions, answers, setCurrentQuestion} = props;

    const statement = resource.statement.replace(/__(\d+)__/g, (text, position) => {
        let placeholder = position;
        const qs = questions[position-1];
        if (qs){
            placeholder = qs.choices[answers[qs.id]] || placeholder;
        }
        return `<div class="close-test-question" onclick="close_text_focus(${position})" data-selected="${question.question_number.toString()===position}">${placeholder}</div>`
    })

    window.close_text_focus = position => setCurrentQuestion(position-1);

    return (
        <>
            <div className="close-text-question">
                <div className="content" dangerouslySetInnerHTML={{__html: statement}}/>
            </div>
            <ChoicesQuestion
                question={question}
                year={year}
                answer={answer}
                answerQuestion={answerQuestion}
                currentQuestion={currentQuestion}
                numberOfQuestions={numberOfQuestions}
            />
        </>
    )
}