import React, { useState } from 'react';
import '../../css/Login.css'
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser, loginUser } from '../../redux/actions/authentication';
import mapAuthenticationStateToProps from './authStateToProps';

const Login = (props) => {

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  // const [loginError, setLoginError] = useState('');

  const { loginUser, getUser, loggedIn, error } = props;
  if (loggedIn) {
    return (
      <Redirect to="/subjects" />
    )
  }
  let loginError;
  if (error) {
    if (error.context === 'login') {
      loginError = error.message;
    }
  }

  const postLogin = (e) => {
    e.preventDefault();
    loginUser(userName, password);
  }

  return (
    <div className="FormCenter ">
      <div className="login-info">
        <section className="login-section">
          <div className="section4-wrapper">
            <div className="sec4left">
              <div className="sec4subheading">
                <p className="testimonials">
                What we do
                </p>
              </div>
              <div className="login-sec-heading">
                <div className="logsection4head">
                  <h4 className="logsec4head">
                  We help students succeed
                  </h4>
                </div>

                <div className="testimony">
                  <p>We take your success very seriously and that is why our system is designed to help you achieve it.</p>
                </div>

                <div className="testdirection">
                  <p className="tdirection">Sign in and get started.</p>
                </div>

              </div>
            </div>
            <div className="sec4right">
              <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/anothergirl.png" alt="sec4 img" className="sec4rightimg" />
            </div>
          </div>
        </section>
      </div>

      <form id='form' onSubmit={postLogin} >
        <div className="form-wrapper">
          <div className="FormField">
            {/* <label className="FormField__Label" htmlFor="email">User Name</label> */}
            <label for="Username" className="form-label">Username</label>
            <input type="name" id="email" class="form-control" placeholder="Enter username" name="email" value={userName} onChange={e => setUserName(e.target.value)} required  aria-label="Enter Username" aria-describedby="basic-addon1"></input>
          </div>

          <div className="FormField">
            {/* <label className="FormField__Label" htmlFor="password">Password</label> */}
            <label for="Password" className="form-label">Password</label>
            <input type="password" id="password" class="form-control" placeholder="Enter your password" name="password" value={password} onChange={e => setPassword(e.target.value)} required ></input>
          </div>
          <p style={{ color: "red" }}>{loginError}</p>

          <div className="FormFields">
            <button className="FormField__Button">Log In</button>
            <Link to="/signup" className="FormField__Link">Create an account</Link>
          </div>
        </div>
      </form>
    </div>
  )
}


export default connect(mapAuthenticationStateToProps, { getUser, loginUser })(Login);