import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging'

const firebaseConfig ={
  apiKey: "AIzaSyBR02IQTYENPQsrEAEtZYNrxcWqXvZpCZI",
  authDomain: "examsapptopicsapi.firebaseapp.com",
  projectId: "examsapptopicsapi",
  storageBucket: "examsapptopicsapi.appspot.com",
  messagingSenderId: "318178335915",
  appId: "1:318178335915:web:849d268664d30074c5ebee",
  measurementId: "G-GK5G3W3PCQ"

}

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAID_KEY } = process.env;
const publicKey = REACT_APP_VAID_KEY;


export const getToken = async (setTokenFound) => {
    let currentToken = '';

    try {
        currentToken = await messaging.getToken ({ vapidKey: publicKey });
        if(currentToken) {
            setTokenFound(true);
        } else {
            setTokenFound(false);
        }
    } catch (error) {
        console.log('An error occured while retrieving token', error);
    }

    return currentToken;
} 
  
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
