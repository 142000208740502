import React, { useState, useEffect } from 'react';
import { getToken } from '../../firebase.js';

const Notifications = (props) => {
    const [isTokenFoound, setTokenFound] = useState(false);

    console.log("Token found", isTokenFoound);

    //to load once
    useEffect(() => {
        let data;

        async function tokenFunc() {
            data = await getToken(setTokenFound);
            if(data) {
                console.log("Token is", data);
            }
            return data;
        }

        tokenFunc()
    }, [setTokenFound])

    return <></>;
}

Notifications.propTypes = {};

export default Notifications;
