import React, {useState, useEffect} from 'react';
import api from "../../api";
import {Link} from 'react-router-dom';
import '../../css/Betweenyear.css';
import endpoints from "../../endpoints";
import _ from "lodash";

export const Betweenyear = (props) => {

    const yearIdfromyearSelect = props.match.params.year_id;
    const subjectIdfromyearSelect = props.match.params.id;
    const [topicItems , setTopicItems] = useState([]);
    const [loading, setLoading] = useState(false);

    // fetches data from the api and converts it to json
    const fetchTopicItems = () => {
        setLoading(true)
        api.get(endpoints.subjectExamQuestions(yearIdfromyearSelect))
            .then(response => {
                setLoading(false);
                const __topics = response.data.questions.map(qt => qt.topic)
                const _topics = _.uniqBy(__topics, 'id')
                setTopicItems(_topics);
            })

                        
     }

    useEffect(() => {
        fetchTopicItems();
        console.log(topicItems); 
    },[]);

    return (
        <div className = "main-div">
            <div className="subject-title">
                        <h4>Subject by Topics</h4>
                    </div>
                    {topicItems.map(topic => (
                        <Link to={`/subjects/${subjectIdfromyearSelect}/questions/${yearIdfromyearSelect}/topic/${topic.id}`} key={topic.id}>
                            <button className="topic-btns">{topic.title}</button>
                        </Link>
                    ))}
        </div>
    )
}
