import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../../css/Contactus.css';
import { useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Map, { Marker } from 'react-map-gl';
import { Link } from 'react-router-dom';


export default function Contactus() {


    const [lat, setLat] = useState(-1.289303);
    const [lng, setLng] = useState(36.783359);

    const [viewport, setViewport] = useState({
        width: "100%",
        height: "100%",
        longitude: lng,
        latitude: lat,
        zoom: 17,
    });


    // this is the method to send emails to the emailjs server
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_j67eoq9', 'template_qnj12m4', form.current, '1wu7WWTBQi5jWJC2S')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    const hideContForm = () => {
        const contform = document.getElementById('hide-on-click');
        const congratsDiv = document.getElementById('show-on-click');
        contform.style.display = 'none';
        congratsDiv.style.display = 'flex';
    }


    return (
        <>
            <div className="contactus-main">
                <div className="cont-heading">
                    <h5 className='hed-cont'>Contact Us</h5>
                </div>
                <div className="contact-wrapper">
                    <div className="map-left">
                        <Map {...viewport} onViewportChange={(newView) => setViewport(newView)}
                            mapboxApiAccessToken='pk.eyJ1Ijoicy1tdXRlZ2kiLCJhIjoiY2w2a2hycW5vMDF6ODNpbzd4NHp4ejJvaCJ9.d7H2TWPhm119nk5juCjTpg'
                            mapStyle={'mapbox://styles/mapbox/streets-v11'}
                            initialViewState={{
                                latitude: lng,
                                longitude: lat,
                            }}>

                            <Marker longitude={36.78332293875877} latitude={-1.2889361427631596} anchor="bottom" >
                                <img id='elimumarker' src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/elimumarker.png" />
                            </Marker>

                        </Map>
                    </div>

                    <div className="cont-right">

                        <div className="cont-info-cards">
                            <div className="office-address">
                                <h5 className='big'>Office Address</h5>
                                <hr className='line-br' />
                                <p className='small'>
                                    6th Floor Senteu Plaza,<br />
                                    Galana/Lenana Road,<br />
                                    P.O Box 22494 - 00505<br />
                                    Nairobi, Kenya.<br />
                                </p>

                            </div>

                            <div className="email-address">
                                <h5 className='big'>Email</h5>
                                <hr className='line-br' />
                                <p className='small'>info@e-limu.org</p>
                            </div>

                            <div className="call-us">
                                <h5 className='big'>Call Us</h5>
                                <hr className='line-br' />
                                <p className='small'>+254798985410</p>
                            </div>
                        </div>
                        <div className="cont-section">
                            <h4><strong>Contact Us</strong></h4>
                            <div className="cont-form">
                                <form ref={form} onSubmit={sendEmail} id='hide-on-click'>
                                    <div class="mb-3">
                                        <label htmlfor="FormControlInput1" className='form-label'>Name</label>
                                        <input type="text" name="user_name" class="form-control" id="FormControlInput1" placeholder="name..." />
                                    </div>
                                    <div class="mb-3">
                                        <label htmlfor="FormControlInput2" className='form-label'>Email</label>
                                        <input type="email" name="user_email" class="form-control" id="FormControlInput2" placeholder="name@email.com" />
                                    </div>
                                    <div class="mb-3">
                                        <label htmlfor="FormControlInput3" className='form-label'>Message</label>
                                        <textarea name="message" class="form-control" id="FormControlInput3" placeholder="Please say something..." />
                                    </div>

                                    <input type="submit" value="Send" className="challenge" onClick={() => hideContForm()} />
                                </form>

                                <div className="congratulation-div" id='show-on-click'>
                                    <img src="https://prepassets.s3.eu-central-1.amazonaws.com/public/images/green-checkmark.png" alt="green checkmark" />
                                    <p className="sub-received">Your Submission has been received</p>
                                    <button className='challenge free' onClick={() => window.location.reload()}>Submit new form</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contactus-footer">
                <hr className='line-brs' />

            </div>

        </>
    )
}
