import axios from 'axios';

const api = axios.create({});

api.interceptors.request.use(function(config){
    if (localStorage.getItem('token') !== null){
        config['headers'] = {
            ...config['headers'],
            'Authorization': `Token ${localStorage.getItem("token")}`
        }
    }
    return config
})

const tournamentApi = axios.create({
    baseURL: 'https://leaderboardapi.e-limu.org/api'
});

export {tournamentApi};
export default api;